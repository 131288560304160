import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams hook
import { BASE_URL } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const UpdateProduct = () => {
    const { productId } = useParams(); // Extract productId from URL parameters
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState('');
    const [subcategory, setSubCategory] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState('');
    const [images, setImages] = useState([]); // For existing images
    const [newImages, setNewImages] = useState([]); // For new images
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products/${productId}`);
                const product = response.data;
                setProductName(product.name);
                setCategory(product.category);
                setSubCategory(product.subcategory);
                setPrice(product.price);
                setStock(product.stock);
                setImages(product.images.map(image => `${BASE_URL}/${image}`)); // Prefix images with BASE_URL

                if (product.description) {
                    const contentState = convertFromRaw(JSON.parse(product.description));
                    setEditorState(EditorState.createWithContent(contentState));
                }
            } catch (error) {
                toast.error("Failed to fetch product details");
            }
        };

        fetchProduct();
    }, [productId]);

    const handleImageChange = (e) => {
        setNewImages([...newImages, ...e.target.files]);
    };

    const handleImageRemove = (index, type) => {
        if (type === 'existing') {
            setImages(images.filter((_, i) => i !== index));
        } else {
            setNewImages(newImages.filter((_, i) => i !== index));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const rawDescription = convertToRaw(editorState.getCurrentContent());
        const description = JSON.stringify(rawDescription);

        if (!productName || !category || !subcategory || !price || (images.length === 0 && newImages.length === 0) || !description) {
            toast.error("All fields are required");
            return;
        }

        const formData = new FormData();
        formData.append('name', productName);
        formData.append('category', category);
        formData.append('subcategory', subcategory);
        formData.append('price', price);
        formData.append('stock', stock);
        images.forEach(image => formData.append('existingImages', image)); // Send existing images as URLs or IDs
        newImages.forEach(image => formData.append('images', image));
        formData.append('description', description);

        try {
            await axios.put(`${BASE_URL}/products/${productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success("Product successfully updated!");
        } catch (error) {
            toast.error("Failed to update product");
        }
    };

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <h1 className="text-2xl mb-4">Update Product</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block">Product Name</label>
                    <input
                        type="text"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        className="w-full p-2 border border-gray-300"
                    />
                </div>
                <div>
                    <label className="block">Category</label>
                    <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="w-full p-2 border border-gray-300"
                    >
                        <option value="">Select a category</option>
                        <option value="school-stationery">School Stationery</option>
                        <option value="office-stationery">Office Stationery</option>
                        <option value="textbooks">Textbooks</option>
                        <option value="services">Services</option>
                    </select>
                </div>
                <div>
                    <label className="block">Sub-Category</label>
                    <select
                        value={subcategory}
                        onChange={(e) => setSubCategory(e.target.value)}
                        className="w-full p-2 border border-gray-300"
                    >
                        <option value="">Select a sub-category</option>
                        {/* School Stationery */}
                        {category === 'school-stationery' && (
                            <>
                                <option value="pens">Pens</option>
                                <option value="writing-books">Writing Books</option>
                                <option value="white-boards">White Boards</option>
                                <option value="other">Other</option>
                            </>
                        )}
                        {/* Office Stationery */}
                        {category === 'office-stationery' && (
                            <>
                                <option value="pens">Pens</option>
                                <option value="notebooks">Notebooks</option>
                                <option value="other">Other</option>
                            </>
                        )}
                        {/* Textbooks */}
                        {category === 'textbooks' && (
                            <>
                                <option value="primary-textbooks">Primary Textbooks</option>
                                <option value="secondary-textbooks">Secondary Textbooks</option>
                                <option value="other">Other</option>
                            </>
                        )}
                        {/* Services */}
                        {category === 'services' && (
                            <>
                                <option value="printing">Printing</option>
                                <option value="photocopying">Photocopying</option>
                                <option value="binding">Binding</option>
                                <option value="schemes">Schemes</option>
                                <option value="other">Other</option>
                            </>
                        )}
                    </select>
                </div>
                <div>
                    <label className="block">Price</label>
                    <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="w-full p-2 border border-gray-300"
                    />
                </div>
                <div>
                    <label className="block">Stock</label>
                    <input
                        type="number"
                        value={stock}
                        onChange={(e) => setStock(e.target.value)}
                        className="w-full p-2 border border-gray-300"
                    />
                </div>
                <div>
                    <label className="block">Images</label>
                    <input
                        type="file"
                        multiple
                        onChange={handleImageChange}
                        accept="image/*"
                        className="w-full p-2 border border-gray-300"
                    />
                    <div className="flex space-x-2 mt-2">
                        {images.length > 0 && images.map((image, index) => (
                            <div key={index} className="relative">
                                <img src={image} alt={`preview-${index}`} className="h-20 w-20 object-cover" />
                                <button
                                    type="button"
                                    onClick={() => handleImageRemove(index, 'existing')}
                                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                                >
                                    X
                                </button>
                            </div>
                        ))}
                        {newImages.length > 0 && newImages.map((image, index) => (
                            <div key={index} className="relative">
                                <img src={URL.createObjectURL(image)} alt={`preview-${index}`} className="h-20 w-20 object-cover" />
                                <button
                                    type="button"
                                    onClick={() => handleImageRemove(index, 'new')}
                                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <label className="block">Description</label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        toolbarClassName="toolbar-class"
                    />
                </div>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">Submit</button>
            </form>
        </div>
    );
};

export default UpdateProduct;
