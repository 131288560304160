import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {BASE_URL} from '../services/api' // Update this to your actual BASE_URL

function Settings() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [rate, setRate] = useState('');
    const [newRate, setNewRate] = useState('');

    useEffect(() => {
        fetchRate();
    }, []);

    const fetchRate = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/rate`);
            setRate(response.data.value);
        } catch (error) {
            toast.error('Failed to fetch rate');
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            toast.error('Passwords do not match');
            return;
        }

        try {
            const userId = 1; // Replace with the actual user ID
            await axios.post(`${BASE_URL}/auth/change-password`, {
                userId,
                oldPassword,
                newPassword
            });
            toast.success('Password changed successfully');
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            toast.error('Failed to change password');
        }
    };

    const handleUpdateRate = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${BASE_URL}/rate`, { value: newRate });
            toast.success('Rate updated successfully');
            setNewRate('');
            fetchRate(); // Refresh the rate
        } catch (error) {
            toast.error('Failed to update rate');
        }
    };

    return (
        <div className="container mx-auto p-4 overflow-hidden">
            <ToastContainer />
            <h1 className="text-2xl font-bold mb-4">Settings</h1>
            
            <div className="mb-6">
                <h2 className="text-xl font-bold mb-4">Change Password</h2>
                <form onSubmit={handleChangePassword} className="space-y-4 bg-white p-6 rounded shadow-md">
                    <div>
                        <label className="block font-medium">Old Password</label>
                        <input
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block font-medium">New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block font-medium">Confirm New Password</label>
                        <input
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded">Change Password</button>
                </form>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-bold mb-4">Update Rate</h2>
                <form onSubmit={handleUpdateRate} className="space-y-4 bg-white p-6 rounded shadow-md">
                    <div>
                        <label className="block font-medium">Current Rate</label>
                        <p className="text-lg">{rate}</p>
                    </div>
                    <div>
                        <label className="block font-medium">New Rate</label>
                        <input
                            type="number"
                            value={newRate}
                            onChange={(e) => setNewRate(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded">Update Rate</button>
                </form>
            </div>
        </div>
    );
}

export default Settings;
