// src/pages/AllProducts.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../services/api';
import { useNavigate } from 'react-router-dom';

 
const AllProducts = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const productsPerPage = 8;

    const categories = [
        'school-stationery', 'office-stationery', 'textbooks', 'services','uniforms'
    ];

    const subcategories = [
        'pens', 'writing-books', 'white-boards', 'other',
        'primary-textbooks', 'secondary-textbooks', 'other',
        'printing', 'photocopying', 'binding', 'schemes', 'other','school-uniforms', 'sports-uniforms', 'other-uniforms'

    ];

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true); // Start loading
            try {
                const response = await axios.get(`${BASE_URL}/products`);
                setProducts(response.data);
                setFilteredProducts(response.data);
            } catch (error) {
                toast.error('Failed to fetch products. Please try again.');
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [searchTerm, selectedCategory, selectedSubcategory]);

    const filterProducts = () => {
        let tempProducts = products;

        if (searchTerm) {
            tempProducts = tempProducts.filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        if (selectedCategory) {
            tempProducts = tempProducts.filter(product => product.category === selectedCategory);
        }

        if (selectedSubcategory) {
            tempProducts = tempProducts.filter(product => product.subcategory === selectedSubcategory);
        }

        setFilteredProducts(tempProducts);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleViewProduct = (productId) => {
        navigate(`/product/${productId}`);
    };

    const handleUpdateProduct = (productId) => {
        navigate(`/update-product/${productId}`);
    };

    const renderProducts = () => {
        const startIndex = (currentPage - 1) * productsPerPage;
        const selectedProducts = filteredProducts.slice(startIndex, startIndex + productsPerPage);

        return selectedProducts.map(product => (
            <div key={product.id} className="bg-white p-4 shadow-md rounded-lg flex flex-col">
                <img
                    src={product.images[0] ? `${BASE_URL}/${product.images[0]}` : 'placeholder-image-url'}
                    alt={product.name}
                    className="w-full h-40 object-cover mb-4 rounded-lg"
                />
                <h2 className="text-lg font-semibold">{product.name}</h2>
                <p className="text-gray-600">${product.price}</p>
                <div className="flex mt-4 space-x-2">
                    <button
                        onClick={() => handleViewProduct(product.id)}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
                    >
                        View
                    </button>
                    <button
                        onClick={() => handleUpdateProduct(product.id)}
                        className="bg-yellow-600 text-white py-2 px-4 rounded-lg hover:bg-yellow-700"
                    >
                        Update
                    </button>
                </div>
            </div>
        ));
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

        return (
            <div className="flex justify-center mt-4 space-x-2">
                {[...Array(totalPages).keys()].map(number => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number + 1)}
                        className={`px-4 py-2 rounded-lg ${currentPage === number + 1 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                    >
                        {number + 1}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <div className="mb-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="p-2 border border-gray-300 rounded-lg w-full md:w-1/3"
                />
                <select
                    value={selectedCategory}
                    onChange={e => setSelectedCategory(e.target.value)}
                    className="p-2 border border-gray-300 rounded-lg w-full md:w-1/3"
                >
                    <option value="">Select Category</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
                <select
                    value={selectedSubcategory}
                    onChange={e => setSelectedSubcategory(e.target.value)}
                    className="p-2 border border-gray-300 rounded-lg w-full md:w-1/3"
                >
                    <option value="">Select Subcategory</option>
                    {subcategories.map(subcategory => (
                        <option key={subcategory} value={subcategory}>{subcategory}</option>
                    ))}
                </select>
            </div>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="spinner"></div> {/* Use the CSS spinner */}
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {renderProducts()}
                    </div>
                    {renderPagination()}
                </>
            )}
        </div>
    );
};

export default AllProducts;
