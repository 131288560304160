import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../services/api';

function USDOrders() {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/orders`);
      const parsedOrders = response.data.map(order => ({
        ...order,
        order_items: JSON.parse(order.order_items)
      }));
      setOrders(parsedOrders);
      setFilteredOrders(parsedOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Error fetching orders');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = orders.filter((order) =>
      order.order_items.some((item) =>
        item.product_name.toLowerCase().includes(query)
      ) && (searchStatus ? order.paynowStatus.toLowerCase().includes(searchStatus.toLowerCase()) : true)
    );
    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(filteredOrders.length / pageSize)) {
      setCurrentPage(page);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().split('T')[0];
  };

  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="p-4">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">USD Orders</h1>
      <div className="flex flex-col md:flex-row mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by product item"
          className="mb-2 md:mb-0 md:mr-2 p-2 border rounded flex-grow"
        />
        <input
          type="text"
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
          placeholder="Search by status"
          className="mb-2 md:mb-0 md:mr-2 p-2 border rounded flex-grow"
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Search
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="spinner"></div> {/* Use the CSS spinner */}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="px-4 py-2 border">Order ID</th>
                <th className="px-4 py-2 border">Customer Name</th>
                <th className="px-4 py-2 border">Phone Number</th>
                <th className="px-4 py-2 border">Amount Paid</th>
                <th className="px-4 py-2 border">Status</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedOrders.map((order, index) => (
                <tr
                  key={order.order_id}
                  className={`border-t hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                >
                  <td className="px-4 py-2 border">{order.order_id}</td>
                  <td className="px-4 py-2 border">{order.customer_name}</td>
                  <td className="px-4 py-2 border">{order.phone_number}</td>
                  <td className="px-4 py-2 border">${order.amount_paid}</td>
                  <td className="px-4 py-2 border">{order.paynowStatus}</td>
                  <td className="px-4 py-2 border">
                    <button
                      className="bg-gray-500 text-white px-2 py-1 rounded"
                      onClick={() => setOrderDetails(order)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-center items-center mt-4">
            <button
              className={`px-3 py-1 mx-1 rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-3 py-1 mx-1">{currentPage}</span>
            <button
              className={`px-3 py-1 mx-1 rounded ${currentPage === Math.ceil(filteredOrders.length / pageSize) ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredOrders.length / pageSize)}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {orderDetails && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-3/4 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Order Details</h2>
            <div className="mb-4">
              <p><strong>Order ID:</strong> {orderDetails.order_id}</p>
              <p><strong>Customer Name:</strong> {orderDetails.customer_name}</p>
              <p><strong>Email:</strong> {orderDetails.email}</p>
              <p><strong>Phone Number:</strong> {orderDetails.phone_number}</p>
              <p><strong>Address:</strong> {orderDetails.address}</p>
              <p><strong>Amount Paid:</strong> ${orderDetails.amount_paid}</p>
              <p><strong>Transaction Date:</strong> {formatDate(orderDetails.transaction_date)}</p>
              <p><strong>Delivery Date:</strong> {formatDate(orderDetails.Delivery_date)}</p>
              <p><strong>Status:</strong> {orderDetails.paynowStatus}</p>
            </div>
            <h3 className="text-lg font-bold mb-2">Order Items</h3>
            <ul className="mb-4">
              {orderDetails.order_items.map((item, index) => (
                <li key={index} className="border-b py-2">
                  {item.product_name} - {item.quantity} x ${item.price}
                </li>
              ))}
            </ul>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={() => setOrderDetails(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default USDOrders;
