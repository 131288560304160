// src/components/Layout.js

import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HiMenu, HiX } from 'react-icons/hi';
import { AuthContext } from '../context/AuthContext';

const Layout = ({ children }) => {
  const [isProductManagementOpen, setProductManagementOpen] = useState(false);
  const [isOrderManagementOpen, setOrderManagementOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { logout } = useContext(AuthContext);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className={`fixed lg:relative inset-y-0 left-0 transform lg:translate-x-0 transition duration-200 ease-in-out ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-[#7F0741] text-white p-4 flex flex-col space-y-2`}>
        <div className="flex justify-between items-center lg:hidden">
          <div className="text-lg font-bold">
           <Link to="/dashboard">Dashboard</Link>
        </div>
          <button onClick={() => setMenuOpen(false)}>
            <HiX size={24} />
          </button>
        </div>
        <div className="lg:block hidden">
          <div className="text-lg font-bold">
          <Link to="/dashboard">Dashboard</Link>
          
        </div>
        </div>
        <div className="border-b border-white"></div>

        <div>
          <div
            className="text-lg font-semibold cursor-pointer"
            onClick={() => setProductManagementOpen(!isProductManagementOpen)}
          >
            Product Management
          </div>
          {isProductManagementOpen && (
            <div className="pl-4 space-y-1">
              <Link to="/create-product" className="hover:bg-purple-700 p-1 block">Create Product</Link>
              <Link to="/all-products" className="hover:bg-purple-700 p-1 block">All Products</Link>
            </div>
          )}
        </div>
        <div className="border-b border-white"></div>

        <div
          className="text-lg font-semibold cursor-pointer"
          onClick={() => setOrderManagementOpen(!isOrderManagementOpen)}
        >
          Order Management
        </div>
        {isOrderManagementOpen && (
          <div className="pl-4 space-y-1">
            <Link to="/all-orders" className="hover:bg-purple-700 p-1 block">Cash Orders</Link>
            <Link to="/usd-orders" className="hover:bg-purple-700 p-1 block">USD Orders</Link>
            <Link to="/zig-orders" className="hover:bg-purple-700 p-1 block">ZIG Orders</Link>
          </div>
        )}
        <div className="border-b border-white"></div>

        <Link to="/discount-management" className="hover:bg-purple-700 p-1 block">Discount Management</Link>
        <div className="border-b border-white"></div>
        <Link to="/stock-management" className="hover:bg-purple-700 p-1 block">Stock Management</Link>
        <div className="border-b border-white"></div>
        <Link to="/settings" className="hover:bg-purple-700 p-1 block">Settings</Link>
        <div className="border-b border-white"></div>
        <button onClick={logout} className="hover:bg-purple-700 p-1 block text-left">Logout</button>
      </div>

      {/* Main content */}
      <div className="flex-1 p-4 overflow-y-auto">
        <button className="lg:hidden mb-4" onClick={() => setMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <HiX size={24} /> : <HiMenu size={24} />}
        </button>
        {children}
      </div>
    </div>
  );
};

export default Layout;
