import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL} from '../services/api'

const DiscountManagement = () => {
    const [discounts, setDiscounts] = useState([]);
    const [discountName, setDiscountName] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');

    useEffect(() => {
        fetchDiscounts();
    }, []);

    const fetchDiscounts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/discounts`);
            setDiscounts(response.data);
        } catch (error) {
            toast.error('Failed to fetch discounts');
        }
    };

    const handleCreateDiscount = async (e) => {
        e.preventDefault();
        if (!discountName || !discountPercentage || !selectedCategory) {
            toast.error('Please provide all discount details');
            return;
        }
        try {
            await axios.post(`${BASE_URL}/discounts`, {
                name: discountName,
                value: discountPercentage,
                category: selectedCategory,
                subcategory: selectedSubcategory
            });
            toast.success('Discount created successfully');
            fetchDiscounts(); // Refresh the discounts list
            setDiscountName('');
            setDiscountPercentage('');
            setSelectedCategory('');
            setSelectedSubcategory('');
        } catch (error) {
            toast.error('Failed to create discount');
        }
    };

    const handleDeleteDiscount = async (discountId) => {
        try {
            await axios.delete(`${BASE_URL}/discounts/${discountId}`);
            toast.success('Discount deleted successfully');
            fetchDiscounts(); // Refresh the discounts list
        } catch (error) {
            toast.error('Failed to delete discount');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <h1 className="text-2xl font-bold mb-4">Discount Management</h1>
            <form onSubmit={handleCreateDiscount} className="space-y-4 mb-4 bg-white p-6 rounded shadow-md">
                <div>
                    <label className="block font-medium">Discount Name</label>
                    <input
                        type="text"
                        value={discountName}
                        onChange={(e) => setDiscountName(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div>
                    <label className="block font-medium">Discount Percentage</label>
                    <input
                        type="number"
                        value={discountPercentage}
                        onChange={(e) => setDiscountPercentage(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div>
                    <label className="block font-medium">Category</label>
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="">Select a category</option>
                        <option value="school-stationery">School Stationery</option>
                        <option value="office-stationery">Office Stationery</option>
                        <option value="textbooks">Textbooks</option>
                        <option value="services">Services</option>
                    </select>
                </div>
                <div>
                    <label className="block font-medium">Sub-Category</label>
                    <select
                        value={selectedSubcategory}
                        onChange={(e) => setSelectedSubcategory(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="">Select a sub-category</option>
                        {/* School Stationery */}
                        {selectedCategory === 'school-stationery' && (
                            <>
                                <option value="pens">Pens</option>
                                <option value="writing-books">Writing Books</option>
                                <option value="white-boards">White Boards</option>
                                <option value="other">Other</option>
                            </>
                        )}
                        {/* Office Stationery */}
                        {selectedCategory === 'office-stationery' && (
                            <>
                                <option value="pens">Pens</option>
                                <option value="notebooks">Notebooks</option>
                                <option value="other">Other</option>
                            </>
                        )}
                        {/* Textbooks */}
                        {selectedCategory === 'textbooks' && (
                            <>
                                <option value="primary-textbooks">Primary Textbooks</option>
                                <option value="secondary-textbooks">Secondary Textbooks</option>
                                <option value="other">Other</option>
                            </>
                        )}
                        {/* Services */}
                        {selectedCategory === 'services' && (
                            <>
                                <option value="printing">Printing</option>
                                <option value="photocopying">Photocopying</option>
                                <option value="binding">Binding</option>
                                <option value="schemes">Schemes</option>
                                <option value="other">Other</option>
                            </>
                        )}
                    </select>
                </div>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">Create Discount</button>
            </form>
            <h2 className="text-xl font-bold mb-4">Existing Discounts</h2>
            {discounts.length === 0 ? (
                <p className="text-gray-500">No discounts yet</p>
            ) : (
                <ul className="space-y-2">
                    {discounts.map(discount => (
                        <li key={discount.id} className="p-4 border border-gray-300 rounded bg-white shadow-md">
                            <div className="flex justify-between items-center">
                                <div>
                                    <span className="font-bold">{discount.name}</span> - <span className="text-blue-600">{discount.value}%</span>
                                    <p className="text-gray-600">{discount.category}</p>
                                    {discount.subcategory && <p className="text-gray-500">{discount.subcategory}</p>}
                                </div>
                                <button
                                    onClick={() => handleDeleteDiscount(discount.id)}
                                    className="bg-red-500 text-white p-2 rounded"
                                >
                                    Delete
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DiscountManagement;
