// src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Login from './pages/Login';
import CreateProduct from './pages/CreateProduct';
import AllProducts from './pages/AllProducts';
import DiscountManagement from './pages/DiscountManagement';
import StockManagement from './pages/StockManagement';
import Settings from './pages/Settings';
import AllOrders from './pages/AllOrders';
import USDOrders from './pages/USDOrders';
import ZIGOrders from './pages/ZIGOrders';
import Dashboard from './pages/Dashboard';
import ViewProduct from './pages/ViewProduct';
import UpdateProduct from './pages/UpdateProduct';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/create-product"
        element={
          <PrivateRoute>
            <Layout>
              <CreateProduct />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/all-products"
        element={
          <PrivateRoute>
            <Layout>
              <AllProducts />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/all-orders"
        element={
          <PrivateRoute>
            <Layout>
              <AllOrders />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/usd-orders"
        element={
          <PrivateRoute>
            <Layout>
              <USDOrders />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/zig-orders"
        element={
          <PrivateRoute>
            <Layout>
              <ZIGOrders />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/discount-management"
        element={
          <PrivateRoute>
            <Layout>
              <DiscountManagement />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/stock-management"
        element={
          <PrivateRoute>
            <Layout>
              <StockManagement />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Layout>
              <Settings />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/product/:productId"
        element={
          <PrivateRoute>
            <Layout>
              <ViewProduct />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/update-product/:productId"
        element={
          <PrivateRoute>
            <Layout>
              <UpdateProduct />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
