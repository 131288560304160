import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BASE_URL } from '../services/api';

const ViewProduct = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products/${productId}`);
                console.log(response.data);
                setProduct(response.data);
                setMainImage(response.data.images ? `${BASE_URL}/${response.data.images[0]}` : '');
                setLoading(false);
            } catch (error) {
                toast.error('Failed to load product. Please try again.');
                setLoading(false);
            }
        };

        fetchProduct();
    }, [productId]);

    const confirmDelete = () => {
        toast.warn(
            ({ closeToast }) => (
                <div>
                    <p>You are about to delete this product. Proceed to confirm?</p>
                    <button
                        onClick={() => {
                            handleDelete();
                            closeToast();
                        }}
                        className="bg-red-600 text-white py-1 px-2 rounded-lg hover:bg-red-700 mr-2"
                    >
                        Yes
                    </button>
                    <button
                        onClick={closeToast}
                        className="bg-gray-600 text-white py-1 px-2 rounded-lg hover:bg-gray-700"
                    >
                        No
                    </button>
                </div>
            ),
            {
                position: "top-center",
                autoClose: false,
                closeOnClick: false,
                draggable: false,
            }
        );
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/products/${productId}`);
            toast.success('Product successfully deleted!');
            navigate('/all-products');
        } catch (error) {
            toast.error('Failed to delete product. Please try again.');
        }
    };

    const handleUpdate = () => {
        navigate(`/update-product/${productId}`);
    };

    const handleImageClick = (image) => {
        setMainImage(`${BASE_URL}/${image}`);
    };

    const parseDescription = (description) => {
        if (!description) return '';
        let textContent = '';

        try {
            const parsedDescription = JSON.parse(description);
            textContent = parsedDescription.blocks.map((block) => {
                const bulletPrefix = block.type === 'unordered-list-item' ? '• ' : '';
                return `${bulletPrefix}${block.text}\n`;
            }).join('');
        } catch (error) {
            console.error('Error parsing description:', error);
            textContent = description;
        }

        return textContent;
    };

    if (loading) return <div className="spinner"></div>;

    if (!product) return <div>Loading...</div>;

    const isDiscounted = product.discountedPrice !== null && product.discountedPrice !== product.price;

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <div className="bg-white p-8 rounded-lg shadow-md flex flex-col md:flex-row max-w-3xl mx-auto">
                <div className="md:w-1/2">
                    <img
                        src={mainImage}
                        alt="Main Product"
                        className="w-full h-auto object-cover rounded-lg"
                    />
                    {product.images && product.images.length > 0 && (
                        <div className="flex mt-4">
                            {product.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={`${BASE_URL}/${image}`}
                                    alt={`Product ${index}`}
                                    className={`w-16 h-16 object-cover m-1 cursor-pointer border ${mainImage === `${BASE_URL}/${image}` ? 'border-blue-600' : 'border-gray-300'}`}
                                    onClick={() => handleImageClick(image)}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="md:w-1/2 md:pl-8 py-5 flex flex-col justify-between">
                    <div>
                        <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
                        <hr className="mb-4" />
                        <p className='mb-2' style={{ whiteSpace: 'pre-line' }}>
                            {parseDescription(product.description)}
                        </p>
                        <p className="mb-2"><strong className='text-[#17A235]'>In stock:</strong> {product.stock}</p>
                        
                        <p className="mb-2"><strong>Category:</strong> {product.category}</p>
                        <p className="mb-2"><strong>Subcategory:</strong> {product.subcategory}</p>

                        <div className="mb-2">
                            {isDiscounted ? (
                                <>
                                    <span className="text-gray-600 line-through">USD ${product.price}</span>
                                    <span className="text-red-600 ml-2">USD ${product.discountedPrice}</span>
                                </>
                            ) : (
                                <span className="text-black text-2xl font-bold">USD ${product.price}</span>
                            )}
                        </div>
                    </div>
                    <div className="flex space-x-4 mt-4">
                        <button
                            onClick={handleUpdate}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
                        >
                            Update Product
                        </button>
                        <button
                            onClick={confirmDelete}
                            className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                        >
                            Delete Product
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProduct;
