import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../services/api';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'tailwindcss/tailwind.css';
import { FaDollarSign, FaChartLine, FaBoxes } from 'react-icons/fa';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [totalOrderSalesRes, totalStockValueRes, discountsOverviewRes, revenueTrendRes] = await Promise.all([
          axios.get(`${BASE_URL}/dashboard/total-order-sales`),
          axios.get(`${BASE_URL}/dashboard/total-stock-value`),
          axios.get(`${BASE_URL}/dashboard/discounts-overview`),
          axios.get(`${BASE_URL}/dashboard/revenue-trend`)
        ]);

        setData({
          totalSalesUSD: totalOrderSalesRes.data.totalSalesUSD,
          totalSalesZIG: totalOrderSalesRes.data.totalSalesZIG,
          totalStockValue: totalStockValueRes.data.totalStockValue,
          activeDiscounts: discountsOverviewRes.data.activeDiscounts,
          usdRevenueTrend: revenueTrendRes.data.usdRevenueTrend,
          zigRevenueTrend: revenueTrendRes.data.zigRevenueTrend
        });
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const { totalSalesUSD, totalSalesZIG, totalStockValue, activeDiscounts, usdRevenueTrend, zigRevenueTrend } = data;

  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  const months = usdRevenueTrend.map(item => item.month);

  const revenueTrend = {
    labels: months,
    datasets: [
      {
        label: 'Revenue (USD)',
        data: usdRevenueTrend.map(item => item.total_sales),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Revenue (ZIG)',
        data: zigRevenueTrend.map(item => item.total_sales),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="p-8 bg-blue-100 rounded-lg shadow flex items-center space-x-4">
          <FaDollarSign className="text-4xl text-blue-500" />
          <div>
            <h2 className="text-lg font-semibold">Total Sales ({currentMonth}, USD)</h2>
            <p className="text-2xl font-bold">${totalSalesUSD}</p>
          </div>
        </div>
        <div className="p-8 bg-green-100 rounded-lg shadow flex items-center space-x-4">
          <FaChartLine className="text-4xl text-green-500" />
          <div>
            <h2 className="text-lg font-semibold">Total Sales ({currentMonth}, ZIG)</h2>
            <p className="text-2xl font-bold">${totalSalesZIG}</p>
          </div>
        </div>
        <div className="p-8 bg-yellow-100 rounded-lg shadow flex items-center space-x-4">
          <FaBoxes className="text-4xl text-yellow-500" />
          <div>
            <h2 className="text-lg font-semibold">Total Stock Value </h2>
            <p className="text-2xl font-bold">${totalStockValue}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 mb-6">
        <div className="p-4 bg-white rounded-lg shadow col-span-1 lg:col-span-2">
          <h2 className="text-lg font-semibold mb-4 text-left text-navy-800">Discounts Overview</h2>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead className="bg-[#0F172A] rounded-t-lg text-white text-left">
              <tr>
                <th className="px-4 py-2 border">Name</th>
                <th className="px-4 py-2 border">Value</th>
              </tr>
            </thead>
            <tbody>
              {activeDiscounts.length > 0 ? (
                activeDiscounts.map(discount => (
                  <tr key={discount.id}>
                    <td className="px-4 py-2 border">{discount.name}</td>
                    <td className="px-4 py-2 border">{discount.value}%</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="px-4 py-2 border text-center">No discounts at the moment</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="p-4 bg-white rounded-lg shadow col-span-1 lg:col-span-3">
          <h2 className="text-lg font-semibold mb-4">Revenue Trend</h2>
          <div className="overflow-hidden rounded-lg">
            <Bar data={revenueTrend} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
