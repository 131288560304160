import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../services/api';

function StockManagement() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [stockFilter, setStockFilter] = useState('');
  const [filterType, setFilterType] = useState('above');

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const filteredProducts = products
    .filter(product =>
      product.name.toLowerCase().includes(search.toLowerCase())
    )
    .filter(product => {
      if (stockFilter === '') return true;
      if (filterType === 'above') {
        return product.stock >= Number(stockFilter);
      } else {
        return product.stock <= Number(stockFilter);
      }
    });

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Stock Management</h1>
      <div className="mb-4 mt-2">
        <input
          type="text"
          placeholder="Search by product name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="p-2 ml-2 md:ml-0 border border-gray-300 rounded"
        />
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="p-2 border md:mt-0 mt-2 border-gray-300 rounded ml-2"
        >
          <option value="above">Stock Above</option>
          <option value="below">Stock Below</option>
        </select>
        <input
          type="number"
          placeholder="Stock filter"
          value={stockFilter}
          onChange={(e) => setStockFilter(e.target.value)}
          className="p-2 border md:mt-0 mt-2 border-gray-300 rounded ml-2"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-4 py-2 border text-left">Product Name</th>
              <th className="px-4 py-2 border text-left">Price</th>
              <th className="px-4 py-2 border text-left">Stock</th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.map((product, index) => (
              <tr
                key={index}
                className={`border-t hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
              >
                <td className="px-4 py-2 border text-left">{product.name}</td>
                <td className="px-4 py-2 border text-left">{product.price}</td>
                <td className="px-4 py-2 border text-left">{product.stock}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={paginatedProducts.length < pageSize}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default StockManagement;
