import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../services/api';

function AllOrders() {
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState('');
  const [modalOrder, setModalOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [currencyFilter, setCurrencyFilter] = useState('');

  useEffect(() => {
    fetchOrders();
  }, [search, currencyFilter]);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/orderscash`, {
        params: {
          search,
          currency: currencyFilter,
        },
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const updateStatus = async (orderId) => {
    try {
      await axios.put(`${BASE_URL}/orderscash/${orderId}`, { status });
      fetchOrders(); // Refresh the orders list after updating status
      if (status === 'Delivered') {
        toast.success('Order status updated to Delivered!');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Failed to update order status.');
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().split('T')[0];
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredOrders = orders
    .filter((order) =>
      order.customer_name.toLowerCase().includes(search.toLowerCase())
    )
    .filter(
      (order) => !currencyFilter || order.currency === currencyFilter
    );

  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="p-4">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">All Orders</h1>
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search by customer name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
        <select
          value={currencyFilter}
          onChange={(e) => setCurrencyFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded ml-2"
        >
          <option value="">All Currencies</option>
          <option value="USD">USD</option>
          <option value="ZIG">ZIG</option>
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-4 py-2 border">Order ID</th>
              <th className="px-4 py-2 border">Customer Name</th>
              <th className="px-4 py-2 border">Amount Due</th>
              <th className="px-4 py-2 border">Delivery Date</th>
              <th className="px-4 py-2 border">Currency</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Actions</th>
              <th className="px-4 py-2 border">View</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order, index) => (
              <tr
                key={order.order_id}
                className={`border-t hover:bg-gray-100 ${
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                }`}
              >
                <td className="px-4 py-2 border">{order.order_id}</td>
                <td className="px-4 py-2 border">{order.customer_name}</td>
                <td className="px-4 py-2 border">{order.amount_due}</td>
                <td className="px-4 py-2 border">
                  {formatDate(order.delivery_date)}
                </td>
                <td className="px-4 py-2 border">{order.currency}</td>
                <td className="px-4 py-2 border">{order.status}</td>
                <td className="px-4 py-2 border">
                  <div className="flex items-center space-x-2">
                    <select
                      className="p-1 border rounded"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded"
                      onClick={() => updateStatus(order.order_id)}
                    >
                      Update
                    </button>
                  </div>
                </td>
                <td className="px-4 py-2 border">
                  <button
                    className="bg-gray-500 text-white px-2 py-1 rounded"
                    onClick={() => setModalOrder(order)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={paginatedOrders.length < pageSize}
        >
          Next
        </button>
      </div>
      {modalOrder && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-3/4 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Order Details</h2>
            <div className="mb-4">
              <p><strong>Email:</strong> {modalOrder.email}</p>
              <p><strong>Phone Number:</strong> {modalOrder.phone_number}</p>
              <p><strong>Transaction Date:</strong> {formatDate(modalOrder.transaction_date)}</p>
            </div>
            <h3 className="text-lg font-bold mb-2">Order Items</h3>
            <ul className="mb-4">
              {JSON.parse(modalOrder.order_items).map((item, index) => (
                <li key={index} className="border-b py-2">
                  {item.product_name} - {item.price} x {item.quantity}
                </li>
              ))}
            </ul>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={() => setModalOrder(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllOrders;
